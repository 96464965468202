import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ title, description, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {description && (
      <meta name="description" content={description} />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} /> }
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      <meta property="og:type" content="website" />

      <script id="mcjs">
        {`
          !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}
          (document,"script","https://chimpstatic.com/mcjs-connected/js/users/9867bddd96f07a16ceaf8610c/473ea059d9451cb80b2407748.js");
        `}
      </script>
    </Helmet>
  );
};

export default Seo;
