import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Content, { HTMLContent } from "../components/Content";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Seo from "../components/Seo";

const AboutPageTemplate = ({ hero, intro, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="section">
      <Hero
        image={hero.image}
        title={hero.title}
        link={hero.link}
        linkText={hero.linkText}
      />
      <Intro
        introImage={intro.introImage}
        title={intro.title}
        text={intro.text}
        link={intro.link}
        linkText={intro.linkText}
        introToggle={intro.introToggle ? intro.introToggle : false}
      />

      <div
        className={`mx-auto max-w-screen-xl w-full px-6 md:py-20`}
      >
        <PageContent
          className={"prose max-w-6xl text-sm md:text-base m-auto md:px-8"}
          content={content}
        />
      </div>
      <hr />
    </div>
  );
};      

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo title={frontmatter.hero.title} description={frontmatter.intro.text} />
      <AboutPageTemplate
        hero={frontmatter.hero}
        intro={frontmatter.intro}
        content={data.markdownRemark.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};
export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        hero {
          title
          link
          linkText
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        intro {
          title
          text
          link
          linkText
          introToggle
          introImage {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
